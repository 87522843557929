import React from "react"
import PropTypes from "prop-types"

export const ModuleItem = ({ type, module, global }) => {
  switch (type) {
    case "Hero":
      const { Hero } = require("@layouts")
      // destructuring for cleaner props below
      const {
        callToActionGroup,
        headline,
        heroContent,
        heroImage,
        heroSocialProof,
        showSocialProof,
      } = module.hero
      return (
        <Hero
          headline={headline}
          heroContent={heroContent}
          src={heroImage.mediaItemUrl}
          alt={heroImage.altText}
          ctaLinkText={callToActionGroup.ctaLinkText}
          ctaLinkUrl={callToActionGroup.ctaLinkUrl}
          showSocialProof={showSocialProof}
          heroSocialProof={heroSocialProof}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "HeroText":
      const { HeroText } = require("@layouts")
      return (
        <HeroText
          ctaButtonType={module.heroText.callToActionGroup.ctaButtonType}
          ctaLinkText={module.heroText.callToActionGroup.ctaLinkText}
          ctaLinkUrl={module.heroText.callToActionGroup.ctaLinkUrl}
          headline={module.heroText.heroTextHeadline}
          heroContent={module.heroText.heroTextContent}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "HeroLandingPage":
      const { HeroLandingPage } = require("@layouts")
      return (
        <HeroLandingPage
          ctaButtonType={module.hero.callToActionGroup.ctaButtonType}
          ctaLinkText={module.hero.callToActionGroup.ctaLinkText}
          ctaLinkUrl={module.hero.callToActionGroup.ctaLinkUrl}
          headline={module.hero.headline}
          formId={module.hero.form}
          formTitle={module.hero.form_title}
          heroContent={module.hero.heroContent}
          cssClasses={module.cssClasses}
          queryParams={global.queryParams}
          htmlId={module.htmlId}
        />
      )
    case "HowCanWeHelpYou":
      const { HowCanWeHelp } = require("@layouts")
      return (
        <HowCanWeHelp
          cssClasses={module.cssClasses}
          global={global.options.howCanWeHelpYou}
          htmlId={module.htmlId}
        />
      )
    case "Billboard":
      const { Billboard } = require("@layouts")
      return (
        <Billboard
          billboardType={module.billboard.billboardType}
          imageSrc={module.billboard.billboardBackgroundImage.mediaItemUrl}
          altText={module.billboard.billboardBackgroundImage.altText}
          billboardYoutubeVideo={module.billboard.billboardYoutubeVideo}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "CtaBillboard":
      const { CtaBillboard } = require("@layouts")
      return (
        <CtaBillboard
          headline={module.headline}
          content={module.content}
          alignment={module.ctaBillboardContentAlignment}
          backgroundColor={module.backgroundColor}
          callToActionGroup={module.callToActionGroup}
          ctaBillboardSecondCallToActionCallToActionGroup={
            module.ctaBillboardSecondCallToActionCallToActionGroup
          }
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "HowWeWork":
      const { HowWeWork } = require("@layouts")
      return (
        <HowWeWork
          cssClasses={module.cssClasses}
          global={global.options.howWeWork}
          htmlId={module.htmlId}
        />
      )
    case "TabGroup":
      const { TabGroup } = require("@layouts")
      return (
        <TabGroup
          title={module.tabGroupTitle}
          introContent={module.tabGroupIntroContent}
          tabs={module.tabGroupTabs}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "SocialProof":
      const { SocialProof } = require("@layouts")
      return (
        <SocialProof
          headline={module.headline}
          logos={module.logos}
          subheading={module.subheading}
          wysiwyg={module.wysiwyg}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Testimonials":
      const { Testimonials } = require("@layouts")
      return (
        <Testimonials
          testimonials={global.testimonials}
          title={module.testimonialsTitle}
          backgroundImage={module.testimonialsBackgroundImage.sourceUrl}
          altText={module.testimonialsBackgroundImage.altText}
          backgroundColor={module.testimonialsBackgroundColor}
          testimonialsImagePlacement={module.testimonialsImagePlacement}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "WhatAreYouLookingFor":
      const { WhatAreYouLookingFor } = require("@layouts")
      return (
        <WhatAreYouLookingFor
          cssClasses={module.cssClasses}
          global={global.options.whatAreYouLookingFor}
          htmlId={module.htmlId}
        />
      )
    case "CenteredContentCard":
      const { CenteredContentCard } = require("@layouts")
      return (
        <CenteredContentCard
          ctaLinkText={module.callToActionGroup.ctaLinkText}
          ctaLinkUrl={module.callToActionGroup.ctaLinkUrl}
          ctaButtonType={module.callToActionGroup.ctaButtonType}
          centeredContentCardTitle={module.centeredContentCardTitle}
          centeredContentCardContent={module.centeredContentCardContent}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Accordion":
      const { Accordions } = require("@layouts")
      return (
        <Accordions
          accordions={module.accordion}
          accordionModuleTitle={module.accordionModuleTitle}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Intro":
      const { SectionIntro } = require("@layouts")
      return (
        <SectionIntro
          introBackgroundColor={module.introBackgroundColor}
          introTitle={module.introTitle}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Features":
      const { Features } = require("@layouts")
      return (
        <Features
          features={module.features}
          introduction={module.introduction}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Wysiwyg":
      const { Wysiwyg } = require("@layouts")
      return (
        <Wysiwyg
          wysiwyg={module.wysiwyg}
          wysiwygHeading={module.wysiwygHeading}
          wysiwygSubheading={module.wysiwygSubheading}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "Combo":
      const { Combo } = require("@layouts")
      return (
        <Combo
          backgroundColor={module.backgroundColor}
          comboCardContent={module.comboCardContent}
          callsToAction={module.callsToAction}
          comboContent={module.comboContent}
          comboFlip={module.comboFlip}
          comboHeading={module.comboHeading}
          comboMediaType={module.comboMediaType}
          comboImage={module.comboImage}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )

    case "CalculatorAll":
      const { AllCalculators } = require("../../Calculator")
      return (
        <AllCalculators cssClasses={module.cssClasses} htmlId={module.htmlId} />
      )
    case "CalculatorAmortization":
      const { MortgagePayment } = require("../../Calculator")
      return (
        <section
          className={`module-calculator-amortization container ${module.cssClasses}`}
          id={module.htmlId}
        >
          <MortgagePayment />
        </section>
      )
    case "CalculatorRefinancing":
      const { LoanRefinance } = require("../../Calculator")
      return (
        <section
          className={`module-calculator-refinancing container ${module.cssClasses}`}
          id={module.htmlId}
        >
          <LoanRefinance />
        </section>
      )
    case "CalculatorPayment":
      const { ExtraPayment } = require("../../Calculator")
      return (
        <section
          className={`module-calculator-payment container ${module.cssClasses}`}
          id={module.htmlId}
        >
          <ExtraPayment />
        </section>
      )
    case "ModuleForm":
      const { Form } = require("@layouts")
      return (
        <Form
          formId={module.form}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )
    case "ModuleTips":
      const { Tips } = require("@layouts")
      return <Tips cssClasses={module.cssClasses} htmlId={module.htmlId} />
    case "ModuleGlossary":
      const { Glossary } = require("@layouts")
      return (
        <Glossary
          glossarySection={module.glossarySection}
          cssClasses={module.cssClasses}
          htmlId={module.htmlId}
        />
      )

    default:
      const { ModulePlaceholder } = require("@layouts")
      return <ModulePlaceholder type={type} />
  }
}

ModuleItem.propTypes = {
  type: PropTypes.string,
  module: PropTypes.object,
}
