import React, { useState, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import map from "lodash/map"
import startsWith from "lodash/startsWith"
import flatMap from "lodash/flatMap"
import groupBy from "lodash/groupBy"
import some from "lodash/some"
import isArray from "lodash/isArray"
import get from "lodash/get"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useDropzone } from "react-dropzone"

function hasOneFieldFilled(data) {
  return some(data, value => {
    if (isArray(value)) {
      return some(value, Boolean)
    }
    return Boolean(value)
  })
}

function formatDataForServer(data) {
  return map(data, (value, id) => {
    if (isArray(value)) {
      return { id, values: value }
    }
    return { id, value }
  })
}

function postDataToServer(formId, payload, endpoint) {
  return fetch(`${endpoint}/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Source-Url":
        window.location.pathname +
        window.location.search +
        window.location.hash,
    },
    body: JSON.stringify({
      variables: {
        clientMutationId: "skdjfjkd933",
        formId: formId,
        payload: payload, // [{ id: "input_1", value: "the value", values: ["value1", "value2"] }]    [FormEntryInput]
      },
      query: `
        mutation CreateFormsEntry($clientMutationId: String!, $formId: Int!, $payload: [FormEntryInput]!) {
          createFormEntry(input: {clientMutationId: $clientMutationId, formId: $formId, payload: $payload}) {
            success
            message
            type
            redirect
            errors {
              field
              message
            }
          }
        }
      `,
    }),
  }).then(r => r.json())
}

function sideLoadFiles({ form_id, uniqueFormId, selectedFiles, endpoint }) {
  return Promise.all(
    flatMap(selectedFiles, (files, fieldId) => {
      return files.map(file => {
        const formData = new FormData()
        formData.append(`file`, file)
        formData.append(`field_id`, fieldId)
        formData.append("form_id", form_id)
        formData.append("gform_unique_id", uniqueFormId)
        formData.append("original_filename", file.name)
        formData.append("name", file.name)

        return fetch(`${endpoint}/wp-json/wpgqlforms/v1/files`, {
          method: "POST",
          body: formData,
        })
          .then(res => res.json())
          .then(r => {
            return {
              temp_filename: r.data.temp_filename,
              uploaded_filename: r.data.uploaded_filename,
              fieldId: `input_${fieldId}`,
            }
          })
      })
    })
  )
    .then(data => {
      return groupBy(data, "fieldId")
    })
    .catch(e => console.log(e))
}

function getFormUniqueId(formId, endpoint) {
  return fetch(`${endpoint}/wp-json/wpgqlforms/v1/form_unique_id`, {
    method: "POST",
    body: JSON.stringify({
      form_id: formId,
    }),
  }).then(res => res.json())
}

/**
 * Form Element
 *
 */
export const Form = ({ form, submitButton, queryParams = {} }) => {
  const d = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          API_URL
        }
      }
    }
  `)

  const [uniqueFormId, setUniqueFormId] = useState("")

  useEffect(() => {
    getFormUniqueId(form.formId, d.site.siteMetadata.API_URL).then(data => {
      setUniqueFormId(data.form_unique_id)
    })
  }, [])

  const [selectedFiles, setSelectedFiles] = useState({})

  const [isSubmitting, setIsSubmitting] = useState(false)
  const renderSubmitButton = submitButton
    ? submitButton
    : () => (
        <button
          type="submit"
          className={`btn primary mt-4 w-full sm:w-auto text-center ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSubmitting}
        >
          Submit
        </button>
      )

  const { register, handleSubmit, errors, setError } = useForm()
  const [submitSuccess, setSubmitSuccess] = useState({
    success: false,
    message: "",
  })

  const onSubmit = data => {
    if (isSubmitting) {
      return
    }

    if (!hasOneFieldFilled(data)) {
      setError(`form`, `form`, `At least one field must be filled!`)
      return
    }

    setIsSubmitting(true)

    sideLoadFiles({
      form_id: form.formId,
      uniqueFormId,
      selectedFiles,
      endpoint: d.site.siteMetadata.API_URL,
    }).then(gform_uploaded_files => {
      data.gform_uploaded_files = JSON.stringify(gform_uploaded_files)
      const payload = formatDataForServer(data)
      postDataToServer(form.formId, payload, d.site.siteMetadata.API_URL)
        .then(({ data }) => {
          const {
            success,
            redirect,
            type,
            message,
            errors,
          } = data.createFormEntry
          if (success) {
            // its was a success
            const isRedirectInternal = startsWith(
              redirect,
              window.location.origin
            )

            switch (type) {
              case "message":
                setSubmitSuccess({
                  success: true,
                  message:
                    message ||
                    "Thank you for contacting us. We will be in touch with you shortly.",
                })
                break
              case "redirect":
                if (isRedirectInternal) {
                  navigate(redirect.replace(window.location.origin, ""), {
                    replace: true,
                  })
                } else {
                  window.location.replace(redirect)
                }
                break
              default:
                console.log(`Unknown confirmation type: ${type}`)
                break
            }
          } else {
            // there was an error
            setError(
              errors.map(({ field, message }) => {
                return {
                  type: "server",
                  name: `input_${String(field).replace(".", "_")}`,
                  message,
                }
              })
            )
          }
          setIsSubmitting(false)
        })
        .catch(e => {
          console.log(e)
          setIsSubmitting(false)
        })
    })
  }

  if (!uniqueFormId) {
    return null
  }

  return (
    <div>
      {submitSuccess.success ? (
        <span
          className="font-semibold text-gray-600"
          dangerouslySetInnerHTML={{ __html: submitSuccess.message }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors[`form`] && errors[`form`].type === "form" && (
            <span className="text-red-600">{errors[`form`].message}</span>
          )}

          {form.form_fields.map(field => {
            const queryParamName = field.queryStringNameField
              ? JSON.parse(field.queryStringNameField)
              : null
            switch (field.type) {
              case "text":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      className="p-2 text-black border border-gray-100 bg-offwhite"
                      type="text"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      placeholder={field.placeholder}
                      defaultValue={
                        queryParams[queryParamName] || field.defaultValue
                      }
                      ref={register({
                        required: field.isRequired,
                        maxLength: field.maxLength,
                      })}
                    />
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "maxLength" && (
                        <span className="text-red-600">
                          The max number of characters is {field.maxLength}!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "textarea":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <textarea
                      className="p-2 text-black border border-gray-100 bg-offwhite"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      placeholder={field.placeholder}
                      defaultValue={
                        queryParams[queryParamName] || field.defaultValue
                      }
                      ref={register({
                        required: field.isRequired,
                        maxLength: field.maxLength,
                      })}
                    />
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "maxLength" && (
                        <span className="text-red-600">
                          The max number of characters is {field.maxLength}!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "number":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      type="number"
                      className="p-2 text-black border border-gray-100 bg-offwhite"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      defaultValue={
                        queryParams[queryParamName] || field.defaultValue
                      }
                      placeholder={field.placeholder}
                      ref={register({
                        required: field.isRequired,
                        min: field.rangeMin,
                        max: field.rangeMax,
                      })}
                    />
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "min" && (
                        <span className="text-red-600">
                          The minimum value is {field.rangeMin}!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "max" && (
                        <span className="text-red-600">
                          The maximum value is {field.rangeMax}!
                        </span>
                      )}
                  </div>
                )
              case "hidden":
                return (
                  <div key={field.id}>
                    <input
                      type="hidden"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      value={
                        queryParams[queryParamName] ||
                        field.value ||
                        field.defaultValue
                      }
                      ref={register}
                    />
                  </div>
                )
              case "select":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <select
                      className="p-2 text-black border border-gray-100 form-select bg-offwhite"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      ref={register({ required: field.isRequired })}
                      defaultValue={
                        queryParams[queryParamName] || field.selectedItem
                      }
                    >
                      {field.choices.map(choice => {
                        return (
                          <option key={choice.value} value={choice.value}>
                            {choice.text}
                          </option>
                        )
                      })}
                    </select>
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "multiselect":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <select
                      className="p-2 text-black border border-gray-100 form-select bg-offwhite"
                      multiple={true}
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      defaultValue={
                        queryParams[queryParamName] || field.selectedItem
                      }
                      ref={register({ required: field.isRequired })}
                    >
                      {field.choices.map(choice => {
                        return (
                          <option key={choice.value} value={choice.value}>
                            {choice.text}
                          </option>
                        )
                      })}
                    </select>
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "checkbox":
                return (
                  <div key={field.id} className="flex flex-col mb-3">
                    <label className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    {field.choices.map((choice, idx) => {
                      const id = field.inputs[idx].id.replace(".", "_")
                      return (
                        <div key={id} className="flex items-center">
                          <input
                            className="mr-2"
                            type="checkbox"
                            id={`input_${id}`}
                            name={`input_${id}`}
                            value={choice.value}
                            defaultChecked={
                              queryParams[queryParamName]
                                ? queryParams[queryParamName] === choice.value
                                : choice.isSelected
                            }
                            ref={register}
                          />
                          <label htmlFor={`input_${id}`}>
                            {choice.text}
                            {field.isRequired && (
                              <span className="text-red-600">*</span>
                            )}
                          </label>
                        </div>
                      )
                    })}
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "radio":
                return (
                  <div key={field.id} className="flex flex-col mb-3">
                    <label className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    {field.choices.map(choice => {
                      return (
                        <div key={choice.value} className="flex items-center">
                          <input
                            className="mr-2"
                            type="radio"
                            name={`input_${field.id}`}
                            value={choice.value}
                            defaultChecked={
                              queryParams[queryParamName]
                                ? queryParams[queryParamName] === choice.value
                                : choice.isSelected
                            }
                            ref={register}
                          />
                          <label htmlFor={`input_${field.id}`}>
                            {choice.text}
                          </label>
                        </div>
                      )
                    })}
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "name":
                return (
                  <div
                    key={field.id}
                    className="flex flex-row flex-wrap justify-between -mx-4"
                  >
                    {field.inputs
                      .filter(i => !i.isHidden)
                      .map(input => {
                        if (input.isHidden) {
                          return null
                        }
                        const id = input.id.replace(".", "_")
                        if (input.choices && input.choices.length) {
                          return (
                            <div>
                              <select
                                key={input.label}
                                id={`input_${id}`}
                                name={`input_${id}`}
                                ref={register({ required: field.isRequired })}
                              >
                                {input.choices.map(choice => {
                                  return (
                                    <option value={choice.value}>
                                      {choice.text}
                                    </option>
                                  )
                                })}
                              </select>
                              {errors[`input_${id}`] &&
                                errors[`input_${id}`].type === "required" && (
                                  <span className="text-red-600">
                                    This field is required!
                                  </span>
                                )}
                              {errors[`input_${id}`] &&
                                errors[`input_${id}`].type === "server" && (
                                  <span className="w-full text-red-600">
                                    {errors[`input_${id}`].message}
                                  </span>
                                )}
                            </div>
                          )
                        }
                        return (
                          <div
                            key={input.label}
                            className={`flex-auto flex flex-col px-4 mb-4`}
                          >
                            <label
                              htmlFor={`input_${id}`}
                              className="font-bold"
                            >
                              {input.label}:
                              {field.isRequired && (
                                <span className="text-red-600">*</span>
                              )}
                            </label>
                            <input
                              className="p-2 text-black border border-gray-100 bg-offwhite"
                              type="text"
                              id={`input_${id}`}
                              name={`input_${id}`}
                              placeholder={input.placeholder}
                              defaultValue={get(
                                queryParams,
                                get(queryParamName, input.label.toLowerCase()),
                                field.defaultValue
                              )}
                              ref={register({ required: field.isRequired })}
                            />
                            {errors[`input_${id}`] &&
                              errors[`input_${id}`].type === "required" && (
                                <span className="text-red-600">
                                  This field is required!
                                </span>
                              )}
                            {errors[`input_${id}`] &&
                              errors[`input_${id}`].type === "server" && (
                                <span className="w-full text-red-600">
                                  {errors[`input_${id}`].message}
                                </span>
                              )}
                          </div>
                        )
                      })}
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="w-full text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "email":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      className="p-2 text-black border border-gray-100 bg-offwhite"
                      type="email"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      placeholder={field.placeholder}
                      defaultValue={
                        queryParams[queryParamName] || field.defaultValue
                      }
                      ref={register({ required: field.isRequired })}
                    />
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "phone":
                return (
                  <div key={field.id} className="flex flex-col mb-4">
                    <label htmlFor={`input_${field.id}`} className="font-bold">
                      {field.label}:
                      {field.isRequired && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      className="p-2 text-black border border-gray-100 bg-offwhite"
                      type="text"
                      id={`input_${field.id}`}
                      name={`input_${field.id}`}
                      placeholder={field.placeholder}
                      defaultValue={
                        queryParams[queryParamName] || field.defaultValue
                      }
                      ref={register({ required: field.isRequired })}
                    />
                    {field.description && <span>{field.description}</span>}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "required" && (
                        <span className="text-red-600">
                          This field is required!
                        </span>
                      )}
                    {errors[`input_${field.id}`] &&
                      errors[`input_${field.id}`].type === "server" && (
                        <span className="text-red-600">
                          {errors[`input_${field.id}`].message}
                        </span>
                      )}
                  </div>
                )
              case "fileupload":
                return (
                  <FileInput
                    key={field.id}
                    field={field}
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                  />
                )
              default:
                return (
                  <div key={field.id} className="hidden">
                    {field.id} : {field.type}
                  </div>
                )
            }
          })}

          {renderSubmitButton({ isSubmitting })}
        </form>
      )}
    </div>
  )
}

function FileInput({ field, setSelectedFiles, selectedFiles }) {
  const onDrop = useCallback(
    acceptedFiles => {
      const files = { ...selectedFiles, [field.id]: acceptedFiles }
      setSelectedFiles(files)
    },
    [selectedFiles, setSelectedFiles]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className="flex flex-col mb-4">
      <label htmlFor={`input_${field.id}`} className="font-bold">
        {field.label}:
        {field.isRequired && <span className="text-red-600">*</span>}
      </label>
      <div
        {...getRootProps({
          className: "border border-gray-100 bg-offwhite p-2 text-black",
        })}
      >
        <input
          {...getInputProps({
            id: `input_${field.id}`,
            name: `input_${field.id}`,
            type: "file",
            multiple: field.multipleFiles,
          })}
        />
        <button
          type="button"
          className="w-full px-4 py-2 text-center border border-gray-300 sm:mr-2 sm:w-auto"
        >
          Choose File
        </button>
        {isDragActive ? (
          <span>Drop the files here ...</span>
        ) : (
          <span>Drag files here, or click to select files</span>
        )}
      </div>
      {selectedFiles[field.id] && selectedFiles[field.id].length > 0 && (
        <div className="pt-2">
          Selected Files: {selectedFiles[field.id].map(f => f.name).join(", ")}
        </div>
      )}
    </div>
  )
}
