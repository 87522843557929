import React, { createContext, useContext } from "react"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { SlidingMenu } from "./Navigation"
import { useToggle } from "@hooks"
import { StickyCTA } from "@elements"

export const AppContext = createContext({
  isMenuOpen: false,
})

export const Layout = ({ children }) => {
  const { isToggled, toggle } = useToggle(false)

  return (
    <AppContext.Provider value={{ isMenuOpen: isToggled, toggleMenu: toggle }}>
      <div className="relative antialiased font-body">
        <Header />
        <SlidingMenu />
        <StickyCTA />
        <main>{children}</main>
        <Footer />
      </div>
    </AppContext.Provider>
  )
}

export const useAppState = () => useContext(AppContext)
