import { graphql, useStaticQuery } from "gatsby"
import find from "lodash/find"

export const useGravityForms = id => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          forms {
            edges {
              node {
                active
                databaseId
                description
                formId
                form_fields {
                  ... on WPGraphQL_TextField {
                    id
                    defaultValue
                    errorMessage
                    isRequired
                    label
                    maxLength
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_TextAreaField {
                    id
                    defaultValue
                    errorMessage
                    isRequired
                    label
                    maxLength
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_HiddenField {
                    id
                    defaultValue
                    type
                    queryStringNameField
                  }
                  ... on WPGraphQL_NumberField {
                    id
                    defaultValue
                    errorMessage
                    isRequired
                    numberFormat
                    label
                    rangeMax
                    placeholder
                    rangeMin
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_SelectField {
                    choices {
                      isSelected
                      text
                      value
                    }
                    selectedItem: selectedItems
                    defaultValue
                    errorMessage
                    id
                    isRequired
                    label
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_MultiSelectField {
                    id
                    choices {
                      isSelected
                      text
                      value
                    }
                    selectedItems
                    defaultValue
                    errorMessage
                    isRequired
                    label
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_RadioField {
                    id
                    choices {
                      isSelected
                      text
                      value
                    }
                    errorMessage
                    defaultValue
                    isRequired
                    placeholder
                    label
                    visibility
                    type
                    queryStringNameField
                  }
                  ... on WPGraphQL_CheckboxField {
                    id
                    choices {
                      isSelected
                      text
                      value
                    }
                    defaultValue
                    errorMessage
                    inputs {
                      id
                      label
                      name
                    }
                    isRequired
                    label
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_EmailField {
                    id
                    defaultValue
                    emailConfirmEnabled
                    errorMessage
                    isRequired
                    label
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_NameField {
                    id
                    defaultValue
                    errorMessage
                    inputs {
                      choices {
                        isSelected
                        value
                        text
                      }
                      id
                      isHidden
                      name
                      label
                    }
                    isRequired
                    placeholder
                    label
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_PhoneField {
                    id
                    errorMessage
                    defaultValue
                    isRequired
                    label
                    phoneFormat
                    placeholder
                    type
                    visibility
                    queryStringNameField
                  }
                  ... on WPGraphQL_FileUploadField {
                    allowedExtensions
                    cssClass
                    description
                    errorMessage
                    id
                    isRequired
                    label
                    maxFileSize
                    maxFiles
                    multipleFiles
                    type
                    visibility
                  }
                }
                id
                title
                trashed
              }
            }
          }
        }
      }
    `
  )

  const { node } = find(wpgraphql.forms.edges, ({ node }) => node.formId === id)

  return node
}
