import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "../Link"
import { useHover } from "@hooks"

export const StickyCTA = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          ...OptionsContactSocial
        }
      }
    `
  )

  const contactInfo =
    wpgraphql.optionsContactSocial.options_global.contactInformation
  const [isHovered, bind] = useHover()
  return (
    <div className="call-now-button-wrapper fixed inset-x-0 z-40 bottom-0 lg:bottom-6 sm:left-auto sm:right-0 sm:pb-12">
      <Link
        className="call-now-button flex items-center justify-center px-8 py-5 sm:py-3 text-lg font-semibold text-gray-400 transition duration-150 bg-yellow-400 hover:bg-yellow-300"
        to={`tel:${contactInfo.phoneNumber.replace(/\(|\)|-|\s/g, "")}`}
        {...bind}
      >
        <svg
          className="h-5 stroke-current"
          viewBox="0 0 41 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.9988 29.7566V35.3376C39.0031 36.3853 38.5655 37.3862 37.7935 38.0944C37.0215 38.8027 35.9867 39.1526 34.9433 39.0583C29.2187 38.4363 23.7198 36.4801 18.8885 33.3471C14.3936 30.4908 10.5826 26.6799 7.72638 22.185C4.58235 17.3317 2.62576 11.8061 2.01511 6.05574C1.92112 5.01557 2.26863 3.98378 2.97276 3.21243C3.67689 2.44107 4.6728 2.00117 5.71721 2.00018H11.2983C13.1658 1.9818 14.7576 3.35074 15.0189 5.19998C15.2545 6.98604 15.6914 8.73972 16.3212 10.4276C16.8323 11.7872 16.5054 13.32 15.484 14.3529L13.1214 16.7155C15.7697 21.373 19.626 25.2293 24.2835 27.8776L26.6461 25.515C27.679 24.4936 29.2118 24.1667 30.5715 24.6778C32.2593 25.3077 34.013 25.7445 35.799 25.9801C37.6697 26.244 39.0457 27.8679 38.9988 29.7566Z"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="ml-4">
          {isHovered ? contactInfo.phoneNumber : `Call Now`}
        </span>
      </Link>
    </div>
  )
}
